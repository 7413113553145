header h3 {
    font-size: 28px;
    display: inline-block;
    padding: 10px 10px;
    margin-bottom: 0px;
}

header {
    background-color: rgb(255 255 255 / 45%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-bottom: 1px solid #d8d8d8;
    color: #fff;
}

.fullht {
    min-height: 100vh;
}

img.back {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.home_banner {
    overflow: hidden;
    margin-top: -63px;
    background-image: url(./assets/imgs/banner.jpg);
    padding: 80px 0px;
    background-position: center;
    background-size: cover;
}

.home_banner h1 {
    font-family: 'Teko', sans-serif;
    font-size: 108px;
    font-style: italic;
}

.home_banner p {
    font-size: 24px;
    padding-left: 10px;
    font-weight: 300;
}

.home_banner::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 40vh;
    bottom: 0px;

    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.938813025210084) 0%, rgba(255, 107, 85, 0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.938813025210084) 0%, rgba(255, 107, 85, 0) 100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.938813025210084) 0%, rgba(255, 107, 85, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#ff6b55", GradientType=1);
}

.borderradiusRounded {
    border-radius: 150px;
}

.fullLeft,
.fullRight {
    position: relative;
    overflow: hidden;
}

.fullLeft::before {
    content: "";
    position: absolute;
    height: 100%;
    left: 0px;
    width: 50%;
    background-color: #efefef;
}

.fullRight::before {
    content: "";
    position: absolute;
    height: 100%;
    right: 0px;
    width: 50%;
    background-color: #efefef;
}

.faded {
    background-color: #efefef;
}

#vision::before {
    background-image: url(./assets/imgs/vision.jpg);
    background-position: center;
    background-size: 150%;
}

#strategy::before {
    background-image: url(./assets/imgs/strategy.jpg);
    background-position: center;
    background-size: 150%;
}

#strategy::before {
    background-image: url(./assets/imgs/strategy.jpg);
    background-position: center;
    background-size: 150%;
}

#engagement_model::before {
    background-image: url(./assets/imgs/engagement_model.jpg);
    background-position: center;
    background-size: 150%;
    background-size: 150%;
}

#assessments::before {
    background-image: url(./assets/imgs/assessments.jpg);
    background-position: center;
    background-size: 250%;
}

.halfht {
    min-height: 50vh;
    height: 600px;
}

.brands {
    padding: 80px 0px;
    position: relative;
}

.brands::before {
    content: "";
    background-image: url(./assets/imgs/fol.jpg);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    opacity: .07;
}

.brands a img {
    max-width: 100%;
    object-fit: contain;
    width: 200px;
}

.brands a {
    display: block;
    color: #333;
    font-size: 36px;
    margin: 0px 0px 20px 0px;
    transition: .5s;
    text-align: center;
}

.brands a:hover {
    opacity: 1;
}

.brands span.separator {
    display: inline-block;
    width: 2px;
    background: #dedede;
    height: 340px;
    border-radius: 10px;
}

.brands .form-control {
    background: transparent;
    border-radius: 0px;
    /* border: none; */
    border-bottom: 1px solid #b9b9b9;
    min-height: 60px;
}


.brands textarea.form-control {
    min-height: 200px;
}

.brands form button.btn {
    padding: 15px 10px;
    background: #888;
    color: #fff;
}


/* .fullRight h2 {
    text-align: right;
} */


.about {
    padding: 80px 0px;
    overflow: hidden;
}


.about h2 {
    font-size: 66px;
    font-weight: 300;
}

.about p {
    max-width: 730px;
    margin: auto;
    padding-bottom: 40px;
    font-size: 24px;
    font-weight: 300;
    text-align: justify;
}

.slides {
    /* position: absolute; */
    position: relative;
    overflow: hidden;
    min-height: 300px;
    border-radius: 10px;
    background-color: #000;
    width: 100%;
    top: 0px;
    left: 0px;
    transition: .5s;
}


.slides h4 {
    position: relative;
    font-size: 56px;
    font-weight: 300;
}

.slider {
    max-width: 600px;
    margin: auto;
    position: relative;
    /* height: 300px; */
}

.slides img {
    position: absolute;
    width: 101%;
    height: 101%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .7;
}


.fullLeft h2,
.fullRight h2 {
    font-weight: 300;
    font-size: 56px;
    margin-bottom: 30px;
}

.fullLeft p,
.fullRight p {
    font-size: 22px;
    font-weight: 300;
}

.container {
    max-width: 85%;
}

.brands h3 {
    font-weight: 300;
    font-size: 46px;
    margin-bottom: 30px;
}

/* 
.container {
    max-width: 1400px;
} */

.fullLeft ul,
.fullRight ul {
    padding-left: 0px;
    margin-top: 30px;
    counter-reset: my-counter;
    list-style-type: none;
}

.fullLeft ul li,
.fullRight ul li {
    font-size: 22px;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    counter-increment: my-counter;
    margin-bottom: 30px;
    position: relative;
    padding-left: 45px;
}


/* .fullLeft ul li::before,
.fullRight ul li::before {
    content: counter(my-counter) "";
    font-weight: bold;
    margin-right: 0.5em;
} */


.fullLeft ul li::after,
.fullRight ul li::after {
    content: "";
    background-image: url(./assets/imgs/traffic-cone.png);
    background-position: center;
    background-size: contain;
    position: absolute;
    width: 19px;
    height: 26px;
    background-repeat: no-repeat;
    left: 10px;
    top: 5px;
}

.form-control:focus,
.form-control {
    box-shadow: none;
}


footer {
    background-color: #555555;
    color: #fff;
}

footer a {
    color: #fff;
}


.cookieHolder {
    position: fixed;
    bottom: 10px;
    font-size: 12px;
    max-width: 100%;
    width: 480px;
    background: rgba(255, 255, 255, 0.7);
    padding: 10px;
    border-radius: 10px;
    left: 10px;
    transition: .5s;
    backdrop-filter: blur(3px);
}

.cookieHolder.hidden {
    left: -500px;
}

.cookieHolder button {
    position: absolute;
    background-color: #d95c5c;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 2px;
    right: 2px;
    font-size: 12px;
    border-radius: 30px;
    padding: 0px;
}

footer a:hover,
footer a:focus,
footer a:focus-visible {

    color: #fff;
    outline: none;
    text-decoration: none;

}

@media(max-width:991px) {

    .fullLeft h2,
    .fullRight h2,
    .brands h3 {
        font-size: 36px;
    }

    .fullLeft ul li,
    .fullRight ul li {
        font-size: 17px;
    }

    .home_banner h1 {
        font-size: 78px;
    }

}


@media(max-width:767px) {


    .about p {
        text-align: center;
    }

    .home_banner h1 {
        font-size: 66px;
    }

    .fullLeft::before,
    .fullRight::before {
        width: 100%;
        background-color: #000000;
        opacity: .6;
        filter: blur(2px);
    }

    .fullLeft,
    .fullRight {
        background-color: #000;
        min-height: 200px;
    }

    .fullLeft h2,
    .fullRight h2 {
        color: #fff;
        font-size: 34px;
    }

    .brands h3 {
        font-size: 24px;
        font-weight: 400;
    }

    .fullLeft *,
    .fullRight * {
        color: #fff;
    }

    .fullLeft h2,
    .fullRight h2 {
        /* font-weight: 700; */
    }


    #engagement_model::before {
        opacity: .3;
    }

    .brands a {
        display: inline-block;
        color: #333;
        font-size: 36px;
        margin: 5px;
        transition: .5s;
        text-align: center;
        max-width: 150px;
    }

    .brands a img {
        width: 100%;
    }
}


@media(max-width:575px) {




    .slides {
        min-height: 210px;
    }

    .home_banner::before {
        height: 60vh;
    }

    header h3 {
        font-size: 20px;
    }

    .fullLeft,
    .fullRight {
        background-color: #000;
        min-height: 200px;
    }

    .home_banner h1 {
        font-size: 44px;
    }

    .home_banner p {
        font-size: 20px;
    }

    .about h2 {
        font-size: 46px;
    }

    .about p {
        font-size: 20px;
    }

    .slides h4 {
        font-size: 36px;
    }

    .fullLeft ul li,
    .fullRight ul li {
        font-size: 18px;
    }

    /* .fullLeft h2,
    .fullRight h2 {
        font-size: 26px;
    } */

}